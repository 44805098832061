<template>
  <b-modal
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    hide-header
    hide-footer
    
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Add New Positions</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->

        <!-- Form -->
        <b-form
          class="p-2"
          @submit="onSubmit"
          
        >
          <b-row>
            <b-col cols="12" md="12">
            
                <b-form-group label="Job Title" label-for="Title">
                  <b-form-input
                    id="Title"
                    v-model="companyData.title"
                    autofocus
                    
                    trim
                    placeholder="Job Title"
                  />

               
                </b-form-group>
         
            </b-col>

            <b-col cols="12" md="12">
           
                <b-form-group
                  label="Company"
                  label-for="companyId"
                 
                >
                  <v-select
                    v-model="companyData.companyId"
                    :options="$store.state.job.companies"
                    :reduce="(val) => val.id"
                    :clearable="false"
                    input-id="companyId"
                    label="name"
                  >
                    <template #option="{ name }">
                      <span class="ml-50 d-inline-block align-middle">
                        {{ name }}</span
                      >
                    </template>

                    <template #selected-option="{ name }">
                      <span class="ml-50 d-inline-block align-middle">
                        {{ name }}</span
                      >
                    </template>
                  </v-select>
              
                </b-form-group>
           
            </b-col>
            <b-col cols="12" md="12">
              <!-- Assignee -->
              <b-form-group label="Assignee" label-for="assignee">
                <v-select
                  v-model="companyData.Users"
                  :options="assigneeOptions"
                  label="userFullName"
                  class="assignee-selector"
                  input-id="userId"
                  multiple
                >
                  <template #option="{ userFullName }">
                    <span class="ml-50 d-inline-block align-middle">
                      {{ userFullName }}</span
                    >
                  </template>

                  <template #selected-option="{ userFullName }">
                    <span class="ml-50 d-inline-block align-middle">
                      {{ userFullName }}</span
                    >
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12">
              <b-form-group label="Begin Date" label-for="BeginDateUtc">
                <flat-pickr
                  v-model="companyData.BeginDateUtc"
                  name="BeginDateUtc"
                  class="form-control"
                  :config="{
                    dateFormat: 'Z',
                    altInput: true,
                    altFormat: 'd-m-Y',
                  }"
                />
              </b-form-group>
            </b-col>

               <b-col cols="12" md="12">
              <b-form-group label="End Date" label-for="EndDateUtc">
                <flat-pickr
                  v-model="companyData.EndDateUtc"
                  name="EndDateUtc"
                  class="form-control"
                  :config="{
                    dateFormat: 'Z',
                    altInput: true,
                    altFormat: 'd-m-Y',
                  }"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
          
                <b-form-group label="Description" label-for="description">
                  <b-form-textarea
                    rows="3"
                    max-rows="6"
                    id="description"
                    v-model="companyData.description"
                   
                    trim
                  />

                
                </b-form-group>
            
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
    
    </template>
  </b-modal>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
  BFormTextarea,
} from "bootstrap-vue";
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import { ref } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    flatPickr,
    BSidebar,
    ToastificationContent,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },

    planOptions: {
      type: Array,
      required: true,
    },
    assigneeOptions: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const blankUserData = {
      title: "",
      companyId: 0,
      description: "",
      BeginDateUtc:new Date(),
      EndDateUtc:moment(new Date()).add(14, 'days'),
    };

    const companies = ref([{ name: "amatis", id: 4 }]);

    const companyData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      companyData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      store
        .dispatch("job/addUser", companyData.value)
        .then(() => {
          emit("refetch-data");
          emit("update:is-add-new-user-sidebar-active", false);
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.messages[0].messageText,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState } =
      formValidation(resetuserData);

    return {
      companyData,
      onSubmit,
      companies,
      refFormObserver,
      getValidationState,

    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
